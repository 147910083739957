
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@font-face {
  font-family: "Pretendard";
  src: url("./assets/fonts/Pretendard-Light.otf");
  font-weight: lighter;
}

@font-face {
  font-family: "Pretendard";
  src: url("./assets/fonts/Pretendard-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Pretendard";
  src: url("./assets/fonts/Pretendard-Regular.otf");
  font-weight: normal;
}